const logotext = "ICK-Dev";
const meta = {
  title: "ICK-Dev",
  description:
    "Arnon Sang-ngern - Junoir developer from JLR Team",
};

const introdata = {
  title: "Arnon Sang-ngern",
  animated: {
    first: "Arnon Sang-ngern",
    second: "ICKDEV",
    third: "Arnon Sang-ngern",
  },
  animated2: {
    first: "JLR Solution",
    second: "Lorenzt Data Style",
    third: "B2G Team",
  },
  description:
    "I'm enthusiastic about making the world a better place by using data and coding to make the seemingly impossible possible. I enjoy learning about new technology and figuring out how to use them to better things.I make an effort to learn new things regularly. I am eager to study and hope to can be a person who makes somethings to improve this world better.",
  your_img_url: "https://cdn.discordapp.com/attachments/885089951207804949/1075367791038832702/ICK.png",
};

const dataabout = {
  title: "Who is ICK-Dev?",
  aboutme:
    "I'm enthusiastic about making the world a better place by using data and coding to make the seemingly impossible possible. I enjoy learning about new technology and figuring out how to use them to better things.I make an effort to learn new things regularly. I am eager to study and hope to can be a person who makes somethings to improve this world better.",
};
const worktimeline = [
  {
    jobtitle: "Software Engineer",
    where: "AmuseM Singapore",
    date: "2022",
    lang: "Java, Go, Kotlin",
  }
];

const skills = [
  {
    name: "Java",
    value: 80,
  },
  {
    name: "Python",
    value: 70,
  },
  {
    name: "R",
    value: 70,
  },
  {
    name: "JavaScript",
    value: 70,
  },
  {
    name: "TypeScript",
    value: 80,
  },
  {
    name: "Svelte",
    value: 70,
  },
  {
    name: "React",
    value: 60,
  },
  {
    name: "Go",
    value: 70,
  },
  {
    name: "Kotlin",
    value: 70,
  },
];

const services = [
  {
    title: "Software Engineer",
    description:
      "Low-level networking and embedded system communication",
  },
  {
    title: "Cross-platform Developer",
    description:
      "Cross-platform development with Java, React-native, Electron",
  },
  {
    title: "Data Engineer",
    description:
      "Develop better algorithm for data analytics",
  },
];

const dataportfolio = [
  {
    img: "https://cdn.discordapp.com/attachments/885089951207804949/1075367791038832702/ICK.png",
    desctiption:
      "My practice JavaScript space!",
    link: "https://lab.link.iamickdev.com",
  },
  {
    img: "https://cdn.discordapp.com/attachments/885089951207804949/952120488853901322/1.png",
    desctiption:
      "YENA - Multipupose discord bot made with discord.js@13",
    link: "https://yena-status.web.app/",
  },
  {
    img: "https://cdn.discordapp.com/attachments/885089951207804949/1075367791038832702/ICK.png",
    desctiption:
      "My personal website made with React",
    link: "https://blog.iamickdev.com",
  },
  {
    img: "https://cdn.discordapp.com/attachments/885089951207804949/952948234748301332/proj.png",
    desctiption:
      "ICKY - Old version of YENA. Multipupose discord bot made with discord.js@12",
    link: "https://yena-status.web.app/",
  },
  {
    img: "https://cdn.discordapp.com/attachments/885089951207804949/1075367791038832702/ICK.png",
    desctiption:
      "API for application testing made with express.js and sqlite3 database",
    link: "https://apis.iamickdev.com/",
  },
  {
    img: "https://cdn.discordapp.com/attachments/885089951207804949/1075367877047234680/image-5.png",
    desctiption:
      "A website for buying event and concert ticket with live-streaming server built-in. Made with Svelte, Express.js, MongoDB",
    link: "https://tiget-passion-fruit.web.app/",
  },
  {
    img: "https://cdn.discordapp.com/attachments/885089951207804949/1075367791038832702/ICK.png",
    desctiption:
      "URL Shortener made with EJS, Express.js, MongoDB",
    link: "https://sl.iamickdev.com/",
  }
];

const contactConfig = {
  YOUR_EMAIL: "work@iamickdev.com",
  YOUR_FONE: "+66 96 882 5197",
  description:
    "Contact us for work by fill this form or direct email Thanks",
  YOUR_SERVICE_ID: "ickdevWebsite",
  YOUR_TEMPLATE_ID: "template_jemg79l",
  YOUR_USER_ID: "aJDc7iFGWZ29YjlLc",
};

const socialprofils = {
  github: "https://github.com/icedkung",
  facebook: "https://facebook.com/little9ss",
  linkedin: "https://th.linkedin.com/in/arnon-sang-ngern-07003721b",
  twitter: "https://twitter.com",
  youtube: "https://www.youtube.com/",
  twitch: "https://twitch.com",
};
export {
  meta,
  dataabout,
  dataportfolio,
  worktimeline,
  skills,
  services,
  introdata,
  contactConfig,
  socialprofils,
  logotext,
};
