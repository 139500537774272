import Turnstile, { useTurnstile } from "react-turnstile";
import Swal from "sweetalert2";
import React from "react";
// ...

function TurnstileWidget() {
    return (
        <div id="ts-show" >
                {/*Turnstile ==>  */}
        <Turnstile
          sitekey={process.env.REACT_APP_TURNSTILE_SITEKEY}
          autoResetOnExpire={true}
          onExpire={() => {
            Swal.fire({
                title: 'Verify expired!',
                icon:"error",
                showCancelButton: true,
                confirmButtonText:"Try again",
                cancelButtonText:"Leave",
                text:"For confirmation that you are not a robot, Please verify again again later!",
                width: 600,
                padding: '3em',
                color: '#000',
                background: '#ffc107',
                confirmButtonColor:"#005e70",
                cancelButtonColor:"#ff0050",
                backdrop: `
                    rgba(0,0,0,0.4)
                    left top
                    no-repeat
                `
                }).then((result)=>{
                    if(result.isConfirmed){
                        //reload or verify by self again
                        Swal.fire({
                            title: 'Verify By yourself or try again!',
                            showCancelButton: true,
                            confirmButtonText:"Try again",
                            cancelButtonText:"Verify by yourself",
                            text:"For confirmation that you are not a robot, Please verify again again later!",
                            width: 600,
                            padding: '3em',
                            color: '#000',
                            background: '#ffc107',
                            confirmButtonColor:"#02af60",
                            cancelButtonColor:"#005e70",
                        }).then((result)=>{
                            if(result.isConfirmed){
                                window.location.reload();
                            }else{
                                localStorage.setItem("trustkey", "error");
                            }
                        })
                          
                          //localStorage.setItem("trustkey", "error");  
                        //window.location.reload();
                    }else{
                        window.location.href="https://www.google.com/search?q=iamickdev";
                    }
                })
            }}
            onVerify={(user) => {
                localStorage.setItem("trustkey", user);
                Swal.fire({
                    title: 'Human Verified, Welcome to ICK website!',
                    icon:"success",
                    html:`
                <b>This website is during migration to new version.</b><br>
                <p>For better experience, please visit new version.</p><br>
                <p>Thank you for your support!</p><br>
                `,
                    width: 600,
                    padding: '3em',
                    color: '#000',
                    background: '#ffc107',
                    confirmButtonColor:"#000",
                    showDenyButton:true,
                    backdrop: `
                      rgba(0,0,0,0.4)
                      left top
                      no-repeat
                    `,
                    
                    timer: 5000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                  })
                  
            }}

          executution="execute"
          onLoad={(widgetId, bound) => {
            // before:
            window.turnstile.execute(widgetId);
            // now:
            bound.execute();
          }}
          onTimeout={() => {
            Swal.fire({
                title: 'Can not verify!',
                icon:"error",
                showCancelButton: true,
                confirmButtonText:"Try again",
                cancelButtonText:"Leave",
                text:"Can not verify that you are not a robot, Please try again later!",
                
                width: 600,
                padding: '3em',
                color: '#000',
                background: '#ffc107',
                confirmButtonColor:"#005e70",
                cancelButtonColor:"#ff0050",
                backdrop: `
                    rgba(0,0,0,0.4)
                    left top
                    no-repeat
                `
                }).then((result)=>{
                    
                    if(result.isConfirmed){
                        Swal.fire({
                            title: 'Verify By yourself or try again!',
                            showCancelButton: true,
                            confirmButtonText:"Try again",
                            cancelButtonText:"Verify by yourself",
                            text:"For confirmation that you are not a robot, Please verify again again later!",
                            width: 600,
                            padding: '3em',
                            color: '#000',
                            background: '#ffc107',
                            confirmButtonColor:"#02af60",
                            cancelButtonColor:"#005e70",
                        }).then((result)=>{
                            if(result.isConfirmed){
                                window.location.reload();
                            }else{
                                localStorage.setItem("trustkey", "error");
                            }
                        })
                    }else{
                        window.location.href="https://www.google.com/search?q=iamickdev";
                    }
                })

            }}
            onError={() => {
                Swal.fire({
                    title: 'Can not verify!',
                    icon:"error",
                    showCancelButton: true,
                    confirmButtonText:"Try again",
                    cancelButtonText:"Leave",
                    text:"Can not verify that you are not a robot, Please try again later!",
                    
                    width: 600,
                    padding: '3em',
                    color: '#000',
                    background: '#ffc107',
                    confirmButtonColor:"#02af60",
                    cancelButtonColor:"#ff0050",
                    backdrop: `
                        rgba(0,0,0,0.4)
                        left top
                        no-repeat
                    `
                    }).then((result)=>{
                        
                        if(result.isConfirmed){
                            Swal.fire({
                                title: 'Verify By yourself or try again!',
                                showCancelButton: true,
                                confirmButtonText:"Try again",
                                cancelButtonText:"Verify by yourself",
                                text:"For confirmation that you are not a robot, Please verify again again later!",
                                width: 600,
                                padding: '3em',
                                color: '#000',
                                background: '#ffc107',
                                confirmButtonColor:"#02af60",
                                cancelButtonColor:"#005e70",
                            }).then((result)=>{
                                if(result.isConfirmed){
                                    window.location.reload();
                                }else{
                                    localStorage.setItem("trustkey", "error");
                                }
                            })
                        }else{
                            window.location.href="https://www.google.com/search?q=iamickdev";
                        }
                    })
    
                }}
        />
        {/* Turnstile <== */}
        </div>
      );
}

export default TurnstileWidget;