import React, { useState } from "react";
import "./style.css";
import { VscGrabber, VscClose } from "react-icons/vsc";
import { Link } from "react-router-dom";
import { logotext ,socialprofils } from "../content_option";
import Themetoggle from "../components/themetoggle";
import TurnstileWidget from "../components/Widget";
import { useEffect } from "react";

const localtrustkey = localStorage.getItem("trustkey");

const Headermain = () => {
  const [isActive, setActive] = useState("false");
  const [trustKey, setTrustKey] = useState("");
  useEffect(() => {
    
    
    if(!localtrustkey){
      localStorage.setItem("trustkey", "verifying");
    }
    if(localtrustkey === "error"){
      handleToggle();
    }
    setTrustKey(localtrustkey);

  }, [localtrustkey]);
  
  const handleToggle = () => {
    setActive(!isActive);
    document.body.classList.toggle("ovhidden");
  };

  return (
    <>
    {/* Header ==> */}
      <header className="fixed-top site__header">
        <div className="d-flex align-items-center justify-content-between">
          <Link  className="navbar-brand nav_ac" to="/">
            {logotext}
          </Link>
          <div className="d-flex align-items-center">
          {/* <Themetoggle /> */}
          <button className="menu__button  nav_ac" onClick={handleToggle}>
            {!isActive ? <VscClose /> : <VscGrabber />}
          </button>
          
          </div>
        </div>

        <div className={`site__navigation ${!isActive ? "menu__opend" : ""}`}>
          <div className="bg__menu h-100">
            <div className="menu__wrapper">
              <div className="menu__container p-3">
                <ul className="the_menu">
                  <li className="menu_item ">
                  <Link  onClick={handleToggle} to="/" className="my-3">Home</Link>
                  </li>
                  <li className="menu_item">
                    <Link  onClick={handleToggle} to="/portfolio" className="my-3"> Projects</Link>
                  </li>
                  <li className="menu_item">
                  <Link onClick={handleToggle} to="/about" className="my-3">About</Link>
                  </li>
                  <li className="menu_item">
                  <Link onClick={handleToggle} to="/contact" className="my-3"> Contact</Link>
                  </li>
                  
                </ul>
              </div>
            </div>
          </div>
          <div className="menu_footer d-flex flex-column flex-md-row justify-content-between align-items-md-center position-absolute w-100 p-3">
            <div className="d-flex">
            <a href={socialprofils.linkedin}>linkedin</a>
            <a href={socialprofils.github}>Github</a>
            </div>
            <TurnstileWidget/>
            <p className="copyright m-0">Arnon Sang-ngern | ICKDEV | ⓒ JLR Solution 2023-2025</p>
          </div>
        </div>
      </header>
      {/* Header <== */}
      
    </>
  );
};

export default Headermain;


